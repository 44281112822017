import {
  Spaceable
} from "./chunk.SHDHDUWS.js";
import {
  ChangesBackgroundColor
} from "./chunk.DRW2NQNF.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-multi-column/jb-multi-column.ts
import { html, LitElement, nothing, unsafeCSS } from "lit";
import { customElement, property, queryAssignedElements } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/jb-multi-column/jb-multi-column.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_multi_column_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
h2 {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 700;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
.slots.threecolumn, .slots.twocolumn, .title, .wrapper.narrow .slots.threecolumn, .wrapper.narrow .slots.twocolumn, .wrapper.narrow .title {
  --_grid-gap: 16px;
  --_grid-template-columns: repeat(12, 1fr);
  display: grid;
  gap: var(--grid-row-gap, 0) var(--grid-column-gap, var(--_grid-gap));
  grid-template-columns: var(--_grid-template-columns);
  width: 100%;
}
@media (min-width: 960px) {
  .slots.threecolumn, .slots.twocolumn, .title, .wrapper.narrow .slots.threecolumn, .wrapper.narrow .slots.twocolumn, .wrapper.narrow .title {
    --_grid-gap: 40px;
  }
}

.title, .wrapper.narrow .slots.threecolumn, .wrapper.narrow .slots.twocolumn, .wrapper.narrow .title {
  padding-inline: 20px;
}

:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

.wrapper {
  padding: var(--spacing-4xl) 0;
}
@media (min-width: 960px) {
  .wrapper.narrow .title h2 {
    grid-column: 4/span 6;
  }
}
.wrapper.narrow .slots.twocolumn > div {
  grid-column: 1/13;
}
@media (min-width: 960px) {
  .wrapper.narrow .slots.twocolumn > div:nth-child(1) {
    grid-column: 4/span 3;
  }
  .wrapper.narrow .slots.twocolumn > div:nth-child(2) {
    grid-column: span 3;
  }
}
.wrapper.narrow .slots.threecolumn > div {
  grid-column: 1/13;
}
@media (min-width: 960px) {
  .wrapper.narrow .slots.threecolumn > div:nth-child(1) {
    grid-column: 4/span 2;
  }
  .wrapper.narrow .slots.threecolumn > div:nth-child(2) {
    grid-column: span 2;
  }
  .wrapper.narrow .slots.threecolumn > div:nth-child(3) {
    grid-column: span 2;
  }
}

h2 {
  color: #141e55;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  grid-column: main;
  letter-spacing: 1px;
  opacity: 0.6;
  padding-bottom: 8px;
  text-transform: uppercase;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 23px;
  line-height: 1.45rem;
}

.title {
  grid-column: main;
}
.title > h2 {
  grid-column: 1/13;
}

.slots {
  grid-column: main;
}
.slots ::slotted(*) {
  padding-bottom: 60px;
}
@media (min-width: 1281px) {
  .slots ::slotted(*) {
    padding-bottom: 68px;
  }
}
@media (min-width: 960px) {
  .slots ::slotted(.last) {
    padding-bottom: 0;
  }
}
.slots > div:last-child ::slotted(:last-child) {
  padding-bottom: 0;
}
@media (min-width: 960px) {
  .slots.twocolumn {
    padding-inline: 20px;
  }
}
.slots.twocolumn > div {
  grid-column: 1/13;
}
@media (min-width: 960px) {
  .slots.twocolumn > div:nth-child(1) {
    grid-column: 1/7;
  }
  .slots.twocolumn > div:nth-child(2) {
    grid-column: 7/13;
  }
}
@media (min-width: 960px) {
  .slots.threecolumn {
    padding-inline: 20px;
  }
}
.slots.threecolumn > div {
  grid-column: 1/13;
}
@media (min-width: 960px) {
  .slots.threecolumn > div:nth-child(1) {
    grid-column: 1/5;
  }
  .slots.threecolumn > div:nth-child(2) {
    grid-column: 5/9;
  }
  .slots.threecolumn > div:nth-child(3) {
    grid-column: 9/13;
  }
}

@media (min-width: 960px) {
  [name=first]::slotted(jb-stock-chart),
  [name=first]::slotted(.component-content-stock-chart) {
    --headline-text-align: right;
  }
}`;

// src/components/jb-multi-column/jb-multi-column.ts
var JbMultiColumn = class extends Spaceable(ChangesBackgroundColor(LitElement)) {
  async firstUpdated() {
    await this.updateComplete;
    this._addClassToLast(this._firstSlotElements);
    this._addClassToLast(this._secondSlotElements);
    this._addClassToLast(this._thirdSlotElements);
  }
  _addClassToLast(slotElement) {
    if (slotElement && slotElement.length > 0) {
      slotElement[slotElement.length - 1].classList.add("last");
    }
  }
  _getContentSpacingProperties() {
    return { grid: true, colsMdUp: "none", type: "no-vertical-space" };
  }
  render() {
    return html`
      <div class="${classMap({ wrapper: true, narrow: !!this.narrow })}">
        ${this.kicker ? html`<div class="title"><h2>${this.kicker}</h2></div>` : nothing}
        <div
          class="${classMap({
      slots: true,
      twocolumn: this.columns === 2,
      threecolumn: this.columns === 3
    })}"
        >
          <div><slot name="first"></slot></div>
          <div><slot name="second"></slot></div>
          ${this.columns === 3 ? html`<div><slot name="third"></slot></div>` : nothing}
        </div>
      </div>
    `;
  }
};
JbMultiColumn.styles = unsafeCSS(jb_multi_column_default);
__decorateClass([
  property({ type: Number })
], JbMultiColumn.prototype, "columns", 2);
__decorateClass([
  property()
], JbMultiColumn.prototype, "kicker", 2);
__decorateClass([
  property({ type: Boolean })
], JbMultiColumn.prototype, "narrow", 2);
__decorateClass([
  queryAssignedElements({ slot: "first" })
], JbMultiColumn.prototype, "_firstSlotElements", 2);
__decorateClass([
  queryAssignedElements({ slot: "second" })
], JbMultiColumn.prototype, "_secondSlotElements", 2);
__decorateClass([
  queryAssignedElements({ slot: "third" })
], JbMultiColumn.prototype, "_thirdSlotElements", 2);
JbMultiColumn = __decorateClass([
  customElement("jb-multi-column")
], JbMultiColumn);

export {
  JbMultiColumn
};
